import { FC, PropsWithChildren, useEffect, useRef } from 'react'
import {
  FieldValues,
  FormProvider,
  useForm,
  UseFormProps,
} from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z, ZodSchema } from 'zod'
import { isEqual } from 'lodash'

interface Props extends Omit<UseFormProps, 'resolver'> {
  formSchema?: ZodSchema
  defaultValues?: FieldValues
  onChange?: (values: FieldValues) => void
  // TODO: Seeing as this a proxy of a useFormProps, we should pass the actual prop 'disabled'
  isReadOnly?: boolean
  onDirty?: (isDirty: boolean) => void
}

export const FormWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  formSchema = z.any(),
  defaultValues,
  onChange,
  isReadOnly,
  onDirty,
  ...props
}) => {
  const formMethods = useForm({
    mode: onChange ? 'onBlur' : 'onSubmit',
    ...props,
    resolver: zodResolver(formSchema),
    disabled: isReadOnly,
    defaultValues,
  })

  // DISCUSSION: useWatch has a disabled param which allows us to unsubscribe if onChange is not provided.
  const values = formMethods.watch()
  const isDirty = formMethods?.formState?.isDirty
  const previousValuesRef = useRef(values)

  // DISCUSSION: This bypasses validation, ideally we would watch the specific fields in a child component of the form.
  useEffect(() => {
    if (onDirty) {
      onDirty(isDirty)
    }
  }, [isDirty, onDirty])

  useEffect(() => {
    const previousValues = previousValuesRef.current
    if (onChange && !isEqual(previousValues, values)) {
      onChange(values)
      previousValuesRef.current = values
    }
  }, [values, onChange])

  // DISCUSSION: We should be calling reset manually.
  useEffect(() => {
    formMethods.reset(defaultValues)
  }, [formMethods, defaultValues])

  return <FormProvider {...formMethods}>{children}</FormProvider>
}
