import { useFormContext } from 'react-hook-form'
import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { InputControl } from '../../Layout/InputControl'

type PasswordInputProps = InputProps & {
  name: string
  label?: string
  inlineLabel?: boolean
}

export const PasswordInput = ({
  name,
  label,
  inlineLabel,
  ...props
}: PasswordInputProps) => {
  const { register } = useFormContext()
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  return (
    <InputControl
      name={name}
      label={label}
      labelSize={props?.size}
      inlineLabel={inlineLabel}
    >
      <InputGroup>
        <Input
          type={isPasswordVisible ? 'text' : 'password'}
          autoComplete={'off'}
          {...register(name)}
          {...props}
        />
        <InputRightElement>
          <Button
            size="sm"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            {isPasswordVisible ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>
    </InputControl>
  )
}
