import {
  Button,
  Flex,
  Stack,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react'
import { Form } from '@components/Forms/Form'
import { TextInput } from '@components/Forms/Inputs/TextInput'
import { z } from 'zod'
import { PasswordInput } from '@components/Forms/Inputs/PasswordInput'
import Link from 'next/link'
import { useUser } from '@lib/firebase/provider'
import { useState } from 'react'
import { FormWrapper } from '@components/Forms/Wrappers/FormWrapper'
import { LoginError } from '../types'

const validationSchema = z.object({
  email: z.string().email(),
  password: z.string().min(3),
})

const loginErrors: LoginError[] = [
  {
    code: 'auth/too-many-requests',
    message:
      'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
  },
  {
    code: 'auth/user-not-found',
    message: 'Invalid login details',
  },
  {
    code: 'auth/wrong-password',
    message: 'Invalid login details',
  },
  {
    code: 'auth/invalid-email',
    message: 'The email is not valid',
  },
]

export const SignInForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [loginError, setLoginError] = useState<string>('')
  const { loginWithEmailAndPassword } = useUser()

  const handleSubmit = async (values) => {
    setIsLoading(true)
    try {
      await loginWithEmailAndPassword(values.email, values.password)
    } catch (err) {
      const currentError = loginErrors.find(
        (loginErr) => loginErr.code === err.code
      )
      if (!currentError) {
        setLoginError('An error occurred, please try again later')
      } else {
        setLoginError(currentError.message)
      }
    }
    setIsLoading(false)
  }

  return (
    <>
      {loginError && (
        <Alert status="error" my={5}>
          <AlertIcon />
          <AlertDescription>{loginError}</AlertDescription>
        </Alert>
      )}
      <FormWrapper formSchema={validationSchema}>
        <Form onSubmit={handleSubmit}>
          <Stack>
            <TextInput name={'email'} label={'Email'} placeholder={'Email'} />
            <PasswordInput
              name={'password'}
              label={'Password'}
              placeholder={'Password'}
            />
          </Stack>
          <Flex align="center" justify="space-between" mt="8">
            <Link href={'/forgotPassword'}>
              <Button variant="link" colorScheme="blue" size="sm">
                Forgot password
              </Button>
            </Link>
          </Flex>
          <Button
            size="lg"
            type="submit"
            mt="8"
            w="full"
            colorScheme="blue"
            fontSize="md"
            fontWeight="bold"
            isLoading={isLoading}
          >
            Sign in
          </Button>
        </Form>
      </FormWrapper>
    </>
  )
}
