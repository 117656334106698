import { ComponentType, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import useIsStaffUser from '@hooks/useIsStaffUser'
import { useUser } from './provider'
import isClientSide from '../../utils/isClientSide'
import { AuthAction } from './AuthAction'
import { core } from './config'

type WhenAuthed = typeof AuthAction.RENDER | typeof AuthAction.REDIRECT_TO_APP

type WhenAuthedBeforeRedirect =
  | typeof AuthAction.RENDER
  | typeof AuthAction.SHOW_LOADER
  | typeof AuthAction.RETURN_NULL

type WhenUnauthedBeforeInit =
  | typeof AuthAction.RENDER
  | typeof AuthAction.REDIRECT_TO_LOGIN
  | typeof AuthAction.SHOW_LOADER
  | typeof AuthAction.RETURN_NULL

type WhenUnauthedAfterInit =
  | typeof AuthAction.RENDER
  | typeof AuthAction.REDIRECT_TO_LOGIN
  | typeof AuthAction.RETURN_NULL
export interface WithUserOptions {
  whenUnauthedBeforeInit?: WhenUnauthedBeforeInit
  whenUnauthedAfterInit?: WhenUnauthedAfterInit
  whenAuthed?: WhenAuthed
  whenAuthedBeforeRedirect?: WhenAuthedBeforeRedirect
  authPageURL?: string
  appPageUrl?: string
  LoaderComponent?: ComponentType | null
  forceRefreshToken?: boolean
  requireStaff?: boolean
}

const willRedirectToLogin = (
  isAuthed: boolean,
  isInitialized: boolean,
  whenUnauthedBeforeInit: WhenUnauthedBeforeInit,
  whenUnauthedAfterInit: WhenUnauthedAfterInit
): boolean =>
  !isAuthed &&
  ((!isInitialized &&
    whenUnauthedBeforeInit === AuthAction.REDIRECT_TO_LOGIN) ||
    (isInitialized && whenUnauthedAfterInit === AuthAction.REDIRECT_TO_LOGIN))

const shouldRedirectToLogin = (
  willRedirect: boolean,
  whenUnauthedBeforeInit: WhenUnauthedBeforeInit,
  isRequestComplete: boolean
): boolean =>
  willRedirect &&
  isClientSide() &&
  (whenUnauthedBeforeInit !== AuthAction.REDIRECT_TO_LOGIN
    ? isRequestComplete
    : true)

const willRedirectToApp = (
  isAuthed: boolean,
  whenAuthed: WhenAuthed
): boolean => isAuthed && whenAuthed === AuthAction.REDIRECT_TO_APP

const shouldRedirectToApp = (
  willRedirect: boolean,
  whenAuthed: WhenAuthed,
  isRequestComplete: boolean
) =>
  willRedirect &&
  isClientSide() &&
  (whenAuthed !== AuthAction.REDIRECT_TO_APP ? isRequestComplete : true)

const withUser =
  <P extends object>({
    whenUnauthedBeforeInit = AuthAction.RENDER,
    whenUnauthedAfterInit = AuthAction.RENDER,
    whenAuthed = AuthAction.RENDER,
    whenAuthedBeforeRedirect = AuthAction.RETURN_NULL,
    authPageURL = core.authPageUrl,
    appPageUrl = '/',
    LoaderComponent = null,
    forceRefreshToken = false,
    requireStaff = false,
  }: WithUserOptions = {}) =>
  (WrappedComponent: ComponentType<P>) =>
  (props: P) => {
    const { user, isInitialized, isRequestComplete } = useUser()
    const isStaff = useIsStaffUser()
    const router = useRouter()
    useEffect(() => {
      if (!isClientSide()) {
        return
      }
      if (
        forceRefreshToken === true &&
        isInitialized === true &&
        isRequestComplete === true &&
        user?.id !== null
      ) {
        user?.getIdToken(forceRefreshToken).catch((error) => {
          console.error('error', error)
        })
      }
    }, [isInitialized, isRequestComplete, user])

    const isAuthed = !!user?.id

    const isGoingToRedirectToLogin = shouldRedirectToLogin(
      willRedirectToLogin(
        isAuthed,
        isInitialized,
        whenUnauthedBeforeInit,
        whenUnauthedAfterInit
      ),
      whenUnauthedBeforeInit,
      isRequestComplete
    )

    const isGoingToRedirectToApp = shouldRedirectToApp(
      willRedirectToApp(isAuthed, whenAuthed),
      whenAuthed,
      isRequestComplete
    )

    const redirectToLogin = useCallback(() => {
      if (isStaff === true) {
        router.push(core.staffAuthPageUrl).then(() => {})
        return
      }
      if (router.pathname !== authPageURL) {
        router.push(authPageURL)
      }
    }, [router, isStaff])

    const redirectToApp = useCallback(() => {
      if (router.pathname !== appPageUrl) {
        router.push(appPageUrl)
      }
    }, [router])

    useEffect(() => {
      if (!isClientSide()) {
        return
      }
      if (isGoingToRedirectToApp) {
        redirectToApp()
      } else if (isGoingToRedirectToLogin) {
        redirectToLogin()
      }
    }, [
      isGoingToRedirectToLogin,
      isGoingToRedirectToApp,
      redirectToApp,
      redirectToLogin,
    ])

    if (!isInitialized || !isRequestComplete) {
      if (whenUnauthedBeforeInit === AuthAction.SHOW_LOADER) {
        return LoaderComponent ? <LoaderComponent /> : <>Loading...</>
      }

      if (whenUnauthedBeforeInit === AuthAction.RETURN_NULL) {
        return null
      }
    }

    if (!isAuthed && isRequestComplete) {
      if (whenUnauthedAfterInit === AuthAction.RETURN_NULL) {
        return null
      }
    }

    if (isAuthed && isRequestComplete) {
      if (whenAuthedBeforeRedirect === AuthAction.SHOW_LOADER) {
        return LoaderComponent ? <LoaderComponent /> : <>Loading...</>
      }
    }

    if (requireStaff && isStaff === false) {
      router.push('/').then(() => {})
    }

    return <WrappedComponent {...props} />
  }

export { withUser }
