import {
  GetServerSidePropsContext,
  NextApiRequest,
  NextApiResponse,
} from 'next'
import Cookies from 'cookies'
import { decodeBase64, encodeBase64 } from './encoding'
import { cookieConfig } from './config'

export interface ReqResObj {
  req: NextApiRequest | GetServerSidePropsContext['req']
  res: NextApiResponse | GetServerSidePropsContext['res']
}

interface ReqResOptionalObj {
  req: NextApiRequest | GetServerSidePropsContext['req']
  res?: NextApiResponse | GetServerSidePropsContext['res']
}

type CookieOptions = Omit<Cookies.Option & Cookies.SetOption, 'sameSite'> & {
  sameSite?: string
}

const createCookiesManager = (
  { req, res }: ReqResOptionalObj,
  {
    keys,
    secure,
  }: { keys?: Cookies.Option['keys']; secure?: Cookies.Option['secure'] } = {}
) => new Cookies(req, res, { keys, secure })

export const getCookie = (
  name: string,
  {
    req,
    res = {
      getHeader: () => [],
      setHeader: () => ({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        call: () => {},
      }),
    } as unknown as NextApiResponse,
  }: ReqResOptionalObj,
  {
    keys,
    secure,
    signed = false,
  }: {
    keys?: Cookies.Option['keys']
    secure?: Cookies.Option['secure']
    signed?: Cookies.SetOption['signed']
  } = {}
) => {
  if (signed) {
    const areCookieKeysDefined = Array.isArray(keys)
      ? keys.length &&
        (keys.filter ? keys.filter((item) => item !== undefined).length : true)
      : !!keys
    if (!areCookieKeysDefined) {
      throw new Error(
        'The "keys" value must be provided when using signed cookies.'
      )
    }
  }
  if (!req) {
    throw new Error('The "req" argument is required when calling `getCookie`.')
  }

  const cookies = createCookiesManager({ req, res }, { keys, secure })
  const cookieVal = cookies.get(name, { signed })
  return cookieVal ? decodeBase64(cookieVal) : undefined
}

export const setCookie = (
  name: string,
  cookieVal: string | undefined,
  { req, res }: ReqResObj,
  {
    keys,
    domain,
    httpOnly,
    maxAge,
    overwrite,
    path,
    sameSite,
    secure,
    signed,
  }: CookieOptions = {}
) => {
  if (signed && !keys) {
    throw new Error(
      'The "keys" value must be provided when using signed cookies.'
    )
  }
  if (!res) {
    throw new Error('The "res" argument is required when calling `setCookie`.')
  }

  const cookies = createCookiesManager({ req, res }, { keys, secure })
  const valToSet = cookieVal == null ? undefined : encodeBase64(cookieVal)

  cookies.set(name, valToSet, {
    domain,
    httpOnly,
    maxAge,
    overwrite,
    path,
    sameSite: sameSite as Cookies.SetOption['sameSite'],
    secure,
    signed,
  })
}

export const deleteCookie = (
  name: string,
  reqResObj: ReqResObj,
  options: CookieOptions
) => {
  setCookie(name, undefined, reqResObj, options)
}

export const getBaseCookieName = () => cookieConfig.name

export const getUserCookieName = () => {
  const baseAuthCookieName = getBaseCookieName()
  return `${baseAuthCookieName}.AuthUser` // do not modify
}

export const getUserSigCookieName = () => `${getUserCookieName()}.sig`

export const getUserTokensCookieName = () => {
  const baseAuthCookieName = getBaseCookieName()
  return `${baseAuthCookieName}.AuthUserTokens` // do not modify
}

export const getUserTokensSigCookieName = () =>
  `${getUserTokensCookieName()}.sig`
