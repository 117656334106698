import { chakra, HTMLChakraProps } from '@chakra-ui/react'

interface LogoProps extends HTMLChakraProps<'svg'> {
  textColor?: string
}

export const Logo: React.FC<LogoProps> = (props) => {
  const { textColor } = props
  const textFill = textColor ?? '#FFF'
  return (
    <chakra.svg viewBox="0 0 6057 3461" fill="none">
      <path
        d="M679.756 3405.9c-68.933 26.071-147.17 39.131-234.712 39.131-286.877 0-430.315-138.787-430.315-416.36 0-262.648 143.438-393.972 430.315-393.972 87.542 0 165.779 13.036 234.712 39.107v134.134c-68.933-29.802-143.439-44.703-223.542-44.703-191.873 0-287.798 88.486-287.798 265.434 0 191.874 95.925 287.822 287.798 287.822 80.103 0 154.609-14.901 223.542-44.727V3405.9ZM967.566 2640.29v670.601h317.411v128.537H819.475V2640.29h148.091ZM1714.19 3316.49c160.938 0 241.418-92.973 241.418-278.871 0-182.924-80.48-274.385-241.418-274.385-165.046 0-247.558 91.461-247.558 274.385 0 185.898 82.512 278.871 247.558 278.871m-401.245-275.518c0-270.843 133.732-406.276 401.245-406.276 263.404 0 395.106 135.433 395.106 406.276 0 269.378-131.702 404.056-395.106 404.056-255.587 0-389.343-134.678-401.245-404.056M2249 3125.35v-485.079h148.091v485.079c0 127.428 65.201 191.141 195.605 191.141 130.379 0 195.58-63.713 195.58-191.141v-485.079h148.091v485.079c0 213.104-114.557 319.679-343.671 319.679-229.139 0-343.696-106.575-343.696-319.679M3416.99 3310.9c165.802 0 248.668-97.601 248.668-292.828 0-166.18-82.866-249.258-248.668-249.258h-164.858V3310.9h164.858Zm-312.948 128.538V2640.3h312.948c264.514 0 396.759 125.916 396.759 377.772 0 280.903-132.245 421.366-396.759 421.366h-312.948ZM3939.5 3439.43v-799.137h153.688l271.031 606.319 265.459-606.319h148.091v799.137h-136.921v-556.609l-216.811 556.609h-126.318l-221.298-556.609v556.609H3939.5Z"
        fill={textFill}
      />
      <path fill={textFill} d="M4945.44 2640.29h148.091v799.138H4945.44z" />
      <path
        d="m5467.94 2991.2-276.605-350.921h187.221l179.523 238.277-90.139 112.644Zm352.102-350.921h187.221l-314.082 399.569 314.082 399.569h-187.221l-220.755-291.719-220.731 291.719h-187.221l628.707-799.138Z"
        fill={textFill}
      />
      <path
        d="m1057.02 418.107 696.955 397.491-696.955 396.074V418.107Z"
        fill="#08a5e1"
      />
      <path
        d="m1753.98 815.586-696.955-397.491 698.868-396.38-1.913 793.871Z"
        fill="#0b7aff"
      />
      <path
        d="m2439.89 1214.52-685.902-398.931 686.209-396.617-.307 795.548Z"
        fill="#1a9bfc"
      />
      <path
        d="m1755.88 21.722 684.321 397.231-686.234 396.64 1.913-793.871Z"
        fill="#0059a1"
      />
      <path
        d="m3127.41 1614.24-687.509-399.71 689.162-395.838-1.653 795.548Z"
        fill="#1a9bfc"
      />
      <path
        d="m2440.2 418.96 688.855 399.71-689.162 395.838.307-795.548Z"
        fill="#6bc1fd"
      />
      <path
        d="m5197.81 425.683-695.349 395.413 695.349 400.135V425.683Z"
        fill="#0b7aff"
      />
      <path
        d="m4502.46 821.098 695.349-395.413L4502.46 26.518v794.58Z"
        fill="#7fd8e2"
      />
      <path
        d="m3816.7 1218.38 685.761-397.278-685.147-398.27-.614 795.548Z"
        fill="#4de8f7"
      />
      <path
        d="M4502.46 26.506 3817.313 422.84l685.147 398.246V26.506Z"
        fill="#a0ecf9"
      />
      <path
        d="m3127.41 1614.24 689.28-395.861-687.627-399.687-1.653 795.548Z"
        fill="#74dff7"
      />
      <path
        d="m3817.32 422.835-688.264 395.838 687.65 399.71.614-795.548Z"
        fill="#a0ecf9"
      />
      <path
        d="m1770.42 1842.7-694.664-398.719 696.978-396.074-2.314 794.793ZM4502.46 1842.7l695.349-396.381-695.349-400.136V1842.7Z"
        fill="#0059a1"
      />
    </chakra.svg>
  )
}
