import {
  Box,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react'
import _ from 'lodash'
import { FC, PropsWithChildren } from 'react'
import { useFormState } from 'react-hook-form'

interface Props extends FormControlProps {
  name: string
  label?: string
  labelSize?: FormLabelProps['size']
  inlineLabel?: boolean
  inlineInputFlex?: number
}

export const InputControl: FC<PropsWithChildren<Props>> = ({
  name,
  label = '',
  labelSize = 'sm',
  inlineLabel = false,
  inlineInputFlex = 2,
  children,
  ...props
}) => {
  const { errors } = useFormState({ name })
  const error = _.get(errors, name)
  const isInvalid = error !== undefined

  if (inlineLabel)
    return (
      <FormControl isInvalid={isInvalid} {...props}>
        <Flex justify={'space-between'} alignItems={'center'}>
          {label && (
            <FormLabel htmlFor={name} size={labelSize} flex={1}>
              {label}
            </FormLabel>
          )}
          <Box flex={inlineInputFlex}>
            {children}
            <FormErrorMessage>{error?.message as string}</FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
    )

  return (
    <FormControl isInvalid={isInvalid} {...props}>
      {label && (
        <FormLabel htmlFor={name} size={labelSize}>
          {label}
        </FormLabel>
      )}
      {children}
      <FormErrorMessage>{error?.message as string}</FormErrorMessage>
    </FormControl>
  )
}
