import { useEffect, useState } from 'react'
import { getCookie } from '@lib/firebase/clientCookie'
import { getTenanancyCookieName } from '@lib/firebase/config'
import { Tenancy, TENANT } from '@lib/firebase/types'
import { getCookie as getCookieSSR } from '@lib/firebase/cookies'

const useIsStaffUser = (): boolean | null => {
  const [isStaff, setIsStaff] = useState<boolean | null>(null)

  useEffect(() => {
    const tenancyValue = getCookie(getTenanancyCookieName())
    setIsStaff(tenancyValue === TENANT.staff)
  }, [])

  return isStaff
}

export default useIsStaffUser

export const isStaffUserSSR = (req, res): boolean => {
  const tenancyValue = getCookieSSR(
    getTenanancyCookieName(),
    {
      req,
      res,
    },
    {}
  ) as Tenancy
  return tenancyValue === TENANT.staff
}
