import { Box, Flex, Spinner } from '@chakra-ui/react'
import { motion, AnimatePresence } from 'framer-motion'
import { Logo } from '../Elements/Logo'

const MotionFlex = motion(Flex)

export const PageLoader = () => (
  <AnimatePresence>
    <MotionFlex
      direction="column"
      w="full"
      h="100vh"
      justify="center"
      alignItems="center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: 'linear' }}
      exit={{ opacity: 0 }}
    >
      <Box px="120" py="50" marginTop="30px" w="sm">
        <Logo />
      </Box>
      <Spinner size="md" />
    </MotionFlex>
  </AnimatePresence>
)
