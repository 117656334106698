import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form'

type FormProps = {
  onSubmit: SubmitHandler<FieldValues>
  style?: React.CSSProperties
  id?: string
}

const Form: React.FC<React.PropsWithChildren<FormProps>> = ({
  onSubmit,
  style = {},
  id,
  children,
}) => {
  const { handleSubmit } = useFormContext()

  return (
    <form
      style={style}
      onSubmit={handleSubmit(onSubmit)}
      method={'post'}
      id={id}
      name={id}
    >
      {children}
    </form>
  )
}

export { Form }
