import { useFormContext } from 'react-hook-form'
import { Input, InputProps } from '@chakra-ui/react'
import React from 'react'
import { InputControl } from '../../Layout/InputControl'

type TextInputProps = InputProps & {
  name: string
  label?: string
  inlineLabel?: boolean
  inputFlex?: number
}

const TextInput = ({
  name,
  label,
  inlineLabel = false,
  inputFlex,
  ...props
}: TextInputProps) => {
  const { register } = useFormContext()

  return (
    <InputControl
      name={name}
      label={label}
      labelSize={props?.size}
      inlineLabel={inlineLabel}
      inlineInputFlex={inputFlex}
    >
      <Input
        type="text"
        {...register(name, {
          setValueAs: (value) => value || undefined,
        })}
        {...props}
      />
    </InputControl>
  )
}

export { TextInput }
